import React from 'react'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import {
  Section,
  Wysiwyg,
  Heading,
  FlexboxCol,
  FlexboxRow,
  Text,
  Container,
} from 'src/components/Base'
import Seo from 'src/components/Seo'
import ReadMoreLink from 'src/components/ReadMoreLink'
import SectionTwoColumns from 'src/components/SectionTwoColumns'
import BaseCard from 'src/components/Cards/BaseCard'
import { graphql } from 'gatsby'
import { v4 as uuid } from 'uuid'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { Hero, ContactPerson } from 'src/sections'
import {
  cSpezialist,
  cImage,
  cImageFirst,
  cCard,
  cCardFirst,
  cPhoneNumber,
  cPhoneNumberCountryCode,
  cLinkGroup,
  cLinkGroupItem,
  cFullHeight,
  cServicePortalLink,
  cWysiwyg,
  cGrayLink,
  cIcon,
} from './styles.module.scss'
import classNames from 'classnames'

const TemplateSupport = ({ data }) => {
  const page = data.wpPage
  const hero = page.atspHero
  const intro = page.atspSupport.supportIntro
  const servicePortal = page.atspSupport.serviceServicePortal
  const serviceSpezialisten = page.atspSupport.serviceSpezialisten
  const spezialistenIcon = serviceSpezialisten.spezialistenIcon
  const spezialistenContact = page.atspSupport.serviceSpezialistenContact
  const spezialistenEmailContact =
    spezialistenContact.serviceSpezialistenContactEmail
  const spezialistenPhoneContact =
    spezialistenContact.serviceSpezialistenContactPhone
  const remoteSupport = page.atspSupport.supportRemote
  const teamviewer = remoteSupport.supportRemoteTeamviewer
  const webex = remoteSupport.supportRemoteWebex
  const contactPerson = page.atspSupport.supportContactPerson
  const breakpoints = useBreakpoint()

  const spezialistenCards = () => {
    let cards = []

    serviceSpezialisten.repeater.map(({ heading, wysiwyg, email }) => {
      cards.push(
        <FlexboxCol width={3} className={cSpezialist}>
          <BaseCard
            className={cFullHeight}
            key={uuid()}
            backgroundColor="#FFFFFF"
            hasBorder
            header={
              <img
                className={cIcon}
                src={spezialistenIcon.localFile.publicURL}
              />
            }
            body={
              <>
                <Heading as="h3" size={3} color="dark">
                  {heading}
                </Heading>
                <Wysiwyg className={cWysiwyg}>{wysiwyg}</Wysiwyg>
              </>
            }
          />
        </FlexboxCol>
      )
    })

    return cards
  }

  return (
    <>
      <Seo
        title={page.seo.title}
        locale={page.language.locale}
        description={page.seo.metaDesc}
      />
      <Hero imageData={hero.heroImage.localFile} heading={hero.heroHeading} />
      <Section hasBackground>
        <Heading isCentered color="dark" as="h2" size={2}>
          {intro.heading}
        </Heading>
        <Wysiwyg isCentered>{intro.wysiwyg}</Wysiwyg>
      </Section>
      <SectionTwoColumns
        contentLeft={
          <>
            <Heading as="h2" size={2} color="dark" isCentered={breakpoints.lg}>
              {servicePortal.heading}
            </Heading>
            <Wysiwyg isCentered={breakpoints.lg}>{servicePortal.text}</Wysiwyg>
            <div className={cServicePortalLink}>
              <ReadMoreLink
                external
                link={servicePortal.link.url}
                text={servicePortal.link.title}
              />
            </div>
          </>
        }
        contentRight=""
      />
      <Section hasBackground>
        <Heading as="h2" size={2} color="dark" isCentered>
          {serviceSpezialisten.spezialistenHeading}
        </Heading>
        <FlexboxRow>{spezialistenCards()}</FlexboxRow>
      </Section>
      <Section>
        <FlexboxRow>
          <FlexboxCol width={6} className={classNames(cImage, cImageFirst)}>
            <GatsbyImage
              className={cFullHeight}
              layout="fullWidth"
              alt={spezialistenContact.image.altText}
              image={getImage(
                spezialistenContact.image.localFile.childImageSharp
              )}
            />
          </FlexboxCol>
          <FlexboxCol width={3} className={cCard}>
            <BaseCard
              isStretched
              backgroundColor="#F7FAFC"
              hasBorder
              header={
                <img
                  className={cIcon}
                  alt={spezialistenPhoneContact.icon.altText}
                  src={spezialistenPhoneContact.icon.localFile.publicURL}
                />
              }
              body={
                <>
                  <Heading as="h3" size={3} color="dark">
                    {spezialistenPhoneContact.heading}
                  </Heading>
                  <Wysiwyg>{spezialistenPhoneContact.wysiwyg}</Wysiwyg>
                  <div className={cPhoneNumber}>
                    <Text>
                      <strong
                        className={classNames(
                          cPhoneNumberCountryCode,
                          'text-primary'
                        )}
                      >
                        A
                      </strong>
                      <a
                        className={cGrayLink}
                        href={`tel:${spezialistenPhoneContact.phoneAt}`}
                      >
                        {spezialistenPhoneContact.phoneAt}
                      </a>
                    </Text>
                  </div>
                  <div className={cPhoneNumber}>
                    <Text>
                      <strong
                        className={classNames(
                          cPhoneNumberCountryCode,
                          'text-primary'
                        )}
                      >
                        CH
                      </strong>
                      <a
                        className={cGrayLink}
                        href={`tel:${spezialistenPhoneContact.phoneCh}`}
                      >
                        {spezialistenPhoneContact.phoneCh}
                      </a>
                    </Text>
                  </div>
                  <div className={cPhoneNumber}>
                    <Text>
                      <strong
                        className={classNames(
                          cPhoneNumberCountryCode,
                          'text-primary'
                        )}
                      >
                        D
                      </strong>
                      <a
                        className={cGrayLink}
                        href={`tel:${spezialistenPhoneContact.phoneDe}`}
                      >
                        {spezialistenPhoneContact.phoneDe}
                      </a>
                    </Text>
                  </div>
                </>
              }
            />
          </FlexboxCol>
          <FlexboxCol width={3} className={cCard}>
            <BaseCard
              isStretched
              backgroundColor="#F7FAFC"
              hasBorder
              header={
                <img
                  className={cIcon}
                  alt={spezialistenEmailContact.icon.altText}
                  src={spezialistenEmailContact.icon.localFile.publicURL}
                />
              }
              body={
                <>
                  <Heading as="h3" size={3} color="dark">
                    {spezialistenEmailContact.heading}
                  </Heading>
                  <Wysiwyg>{spezialistenEmailContact.wysiwyg}</Wysiwyg>
                  {spezialistenEmailContact.email && (
                    <ReadMoreLink
                      external
                      link={`mailto:${spezialistenEmailContact.email}`}
                      text={spezialistenEmailContact.email}
                    />
                  )}
                </>
              }
            />
          </FlexboxCol>
        </FlexboxRow>
      </Section>
      <Section hasBackground>
        <Container isBoxed>
          <Heading as="h2" color="dark" size={2} isCentered>
            {remoteSupport.heading}
          </Heading>
          <Wysiwyg isCentered>{remoteSupport.intro}</Wysiwyg>
        </Container>

        <FlexboxRow>
          <FlexboxCol width={3} className={classNames(cCard, cCardFirst)}>
            <BaseCard
              isStretched
              backgroundColor="#FFFFFF"
              hasBorder
              header={
                <img
                  alt={teamviewer.icon.altText}
                  src={teamviewer.icon.localFile.publicURL}
                />
              }
              body={
                <>
                  <Heading as="h3" size={3} color="dark">
                    {teamviewer.heading}
                  </Heading>
                  <Wysiwyg>{teamviewer.wysiwyg}</Wysiwyg>
                  <div className={cLinkGroup}>
                    <ReadMoreLink
                      external
                      className={cLinkGroupItem}
                      link={teamviewer.linkQuickjoin.url}
                      text={teamviewer.linkQuickjoin.title}
                    />
                    <ReadMoreLink
                      external
                      className={cLinkGroupItem}
                      link={teamviewer.linkQuicksupport.url}
                      text={teamviewer.linkQuicksupport.title}
                    />
                  </div>
                </>
              }
            />
          </FlexboxCol>
          <FlexboxCol width={3} className={cCard}>
            <BaseCard
              isStretched
              backgroundColor="#FFFFFF"
              hasBorder
              header={
                <img
                  alt={webex.icon.altText}
                  src={webex.icon.localFile.publicURL}
                />
              }
              body={
                <>
                  <Heading as="h3" size={3} color="dark">
                    {webex.heading}
                  </Heading>
                  <Wysiwyg>{webex.wysiwyg}</Wysiwyg>
                  <ReadMoreLink
                    external
                    link={webex.linkWebex.url}
                    text={webex.linkWebex.title}
                  />
                </>
              }
            />
          </FlexboxCol>
          <FlexboxCol width={6} className={cImage}>
            <GatsbyImage
              className={cFullHeight}
              layout="fullWidth"
              alt={remoteSupport.image.altText}
              image={getImage(remoteSupport.image.localFile.childImageSharp)}
            />
          </FlexboxCol>
        </FlexboxRow>
      </Section>
      <ContactPerson
        heading={contactPerson.heading}
        name={contactPerson.contactPerson.title}
        extensionNumber={
          contactPerson.contactPerson.atspcontacts.contactData.extension
        }
        email={contactPerson.contactPerson.atspcontacts.contactData.email}
        portrait={
          contactPerson.contactPerson.atspcontacts.portrait.localFile
            .childImageSharp.gatsbyImageData
        }
        altText={contactPerson.contactPerson.atspcontacts.portrait.altText}
      />
    </>
  )
}

export const pageQuery = graphql`
  query SupportPageById($id: String!) {
    wpPage(id: { eq: $id }) {
      title
      content
      language {
        locale
      }
      seo {
        title
        metaDesc
      }
      atspHero {
        heroHeading
        heroImage {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, width: 2880, quality: 90)
            }
          }
        }
      }
      atspSupport {
        supportIntro {
          heading
          wysiwyg
        }
        serviceSpezialisten {
          spezialistenHeading
          spezialistenIcon {
            localFile {
              publicURL
            }
          }
          repeater {
            email
            heading
            wysiwyg
          }
        }
        serviceServicePortal {
          heading
          text
          link {
            title
            url
          }
        }
        serviceSpezialistenContact {
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          serviceSpezialistenContactEmail {
            heading
            wysiwyg
            icon {
              altText
              localFile {
                publicURL
              }
            }
          }
          serviceSpezialistenContactPhone {
            heading
            phoneAt
            phoneCh
            phoneDe
            wysiwyg
            icon {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
        supportContactPerson {
          contactPerson {
            ... on WpKontakt {
              id
              title
              atspcontacts {
                contactData {
                  email
                  extension
                }
                portrait {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(layout: FULL_WIDTH)
                    }
                  }
                }
              }
            }
          }
          heading
        }
        supportRemote {
          heading
          intro
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          supportRemoteTeamviewer {
            heading
            linkQuickjoin {
              title
              url
            }
            linkQuicksupport {
              title
              url
            }
            wysiwyg
            icon {
              altText
              localFile {
                publicURL
              }
            }
          }
          supportRemoteWebex {
            heading
            wysiwyg
            linkWebex {
              title
              url
            }
            icon {
              altText
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  }
`

export default TemplateSupport
